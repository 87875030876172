<template>
  <ctk-dialog
    :value="dialogValue"
    modal-class="delete-payment-source-dialog"
    hide-header
    hide-footer
    persistent
    @handle-close="close"
  >
    <div class="delete-payment-source-dialog__wrapper">
      <div class="delete-payment-source-dialog__header tw-flex tw-justify-end">
        <ctk-dialog-close-button
          class="delete-payment-source-dialog__header__close"
          data-test="close"
          @click.native="close"
        />
      </div>
      <div class="delete-payment-source-dialog__content tw-py-8 tw-px-4 md:tw-px-10">
        <h1
          class="delete-payment-source-dialog__content__title tw-text-xl tw-text-secondary tw-mb-8 tw-font-normal"
          v-text="$t('account.payment-sources.paragraphs.delete_payment_source')"
          data-test="title"
        />
        <stripe-card-item-card
          v-if="paymentSource"
          :type="paymentSource.card_brand"
          :holder-name="paymentSource.card_holder"
          :last-four="paymentSource.last_four_digits"
          :expires-at="paymentSource.expiration_date"
          class="tw-w-full tw-p-3 tw-rounded"
          data-test="card-item"
        />
      </div>
      <div
        class="tw-flex tw-flex-col md:tw-flex-row md:tw-justify-between md:tw-items-center tw-w-full tw-px-4 tw-py-4 md:tw-py-8 md:tw-px-10"
      >
        <ui-button
          type="button"
          variant="link"
          class="tw-w-full md:tw-w-auto tw-mb-4 md:tw-mb-0"
          data-test="close-link"
          @click.prevent="close"
        >
          {{ $t('close') | capitalize }}
        </ui-button>

        <ui-button
          :loading="$wait.is(loader)"
          :disabled="$wait.is(loader)"
          variant="danger"
          type="button"
          data-test="submit"
          @click="deletePaymentSource"
        >
          {{ $t('app.buttons.delete') }}
        </ui-button>
      </div>
    </div>
  </ctk-dialog>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'
  import { computed } from '@vue/composition-api'

  import { PaymentSource } from '@/resources'
  import { showToaster } from '@/services/Toaster'
  import CtkDialog from '@/components/CtkDialog/index.vue'
  import CtkDialogCloseButton from '@/components/CtkDialog/_subs/CtkDialogCloseButton/index.vue'
  import StripeCardItemCard from '@/components/Stripe/StripeCardItem/_subs/StripeCardItemCard/index.vue'

  import useModelGetterSetter from '@/composables/useModelGetterSetter'
  import { EventBus } from '@/services/EventBus'

  /**
   * @module component - DeletePaymentSourceDialog
   * @param {object} [paymentSource=null]
   */
  export default {
    name: 'DeletePaymentSourceDialog',
    props: {
      value: {
        type: Boolean,
        default: null
      },
      paymentSource: {
        type: Object,
        default: null
      }
    },
    components: {
      CtkDialog,
      CtkDialogCloseButton,
      StripeCardItemCard
    },
    setup (props) {
      const { state: dialogValue } = useModelGetterSetter(props, 'value')
      // @ts-ignore
      const loader = computed(() => props.paymentSource && `deleting ${props.paymentSource.source_id} payment source`)

      return {
        dialogValue,
        loader
      }
    },
    computed: {
      ...mapGetters('auth', [
        'getCid'
      ])
    },
    methods: {
      ...mapActions('auth', [
        'retrievePaymentSources'
      ]),
      close () {
        this.dialogValue = false
      },
      /**
       * @function deletePaymentSource
       */
      deletePaymentSource () {
        if (this.$matomo) {
          this.$matomo.trackEvent('Account', 'Payment Sources', 'Delete Card')
        }

        this.$wait.start(this.loader)
        PaymentSource.delete({
          cid: this.getCid,
          psid: this.paymentSource.source_id
        })
          .then(async () => {
            showToaster(this, this.$t('account.payment-sources.paragraphs.deleted'), {
              type: 'success',
              position: 'bottom-right'
            })

            EventBus.$emit('account:payment-sources:refresh-list')
            this.retrievePaymentSources()
              .finally(() => {
                this.$wait.end(this.loader)
                this.close()
              })
          })
          .catch(() => {
            this.$wait.end(this.loader)
          })
      }
    }
  }
</script>

<style lang="scss" scoped>
.delete-payment-source-dialog__header__close {
  right: 0px;
  top: 0px;
}
.delete-payment-source-dialog__content .stripe-card-item-card {
  border: 1px solid #D2D2D2;
}
.delete-payment-source-dialog__content__title {
  position: relative;
}
.delete-payment-source-dialog__content__title::after {
  position: absolute;
  left: 0px;
  width: 50%;
  content: '';
  bottom: -0.75rem;
  height: 1px;
  background-color: $divider;
}
</style>
