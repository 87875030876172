<template>
  <div class="payment-sources">
    <h2
      v-text="$t('account.payment-sources.title')"
      class="payment-sources__title tw-text-xl tw-border-0 tw-border-solid tw-mb-12 tw-border-b tw-border-gray-900"
    />

    <div
      class="payment-sources__payment-plan tw-mb-8 md:tw-mb-12"
      data-test="payment-plan"
    >
      <div
        v-if="$wait.is('fetching company')"
        data-test="loading"
      >
        <ctk-skeleton-block
          width="320px"
          height="32px"
          level="2"
          loading
          class="tw-mb-4"
        />
        <ctk-skeleton-view
          depth="1"
          class="tw-flex tw-justify-between tw-mb-2"
        >
          <ctk-skeleton-block
            width="200px"
            height="32px"
            level="3"
            loading
          />
        </ctk-skeleton-view>
      </div>
      <template
        v-if="!$wait.is('fetching company')"
      >
        <div
          v-text="$t('app.titles.payment_conditions')"
          class="payment-sources__payment-plan__header tw-flex tw-items-center tw-p-3 tw-rounded-tl tw-rounded-tr tw-font-medium"
          data-test="header"
        />
        <div
          class="payment-sources__payment-plan__content tw-p-3 tw-rounded-bl tw-rounded-br"
          data-test="content"
        >
          <ul class="tw-p-0 tw-m-0">
            <li
              v-for="(check, k) in checks"
              :key="k"
              class="tw-flex tw-items-center"
              data-test="check"
            >
              <ui-material-icon
                name="check"
                class="tw-text-base tw-mr-1"
              />
              <span
                v-text="check"
              />
            </li>
          </ul>
        </div>
      </template>

      <p
        v-if="paymentMode === 'immediate'"
        class="tw-italic tw-leading-tight tw-mt-4"
        v-text="$t('account.payment-sources.paragraphs.update_payment_plan')"
        data-test="update-payment-plan"
      />
    </div>

    <div
      v-if="$wait.is('fetching payment sources')"
      data-test="loading"
    >
      <ctk-skeleton-block
        width="320px"
        height="32px"
        level="2"
        loading
        class="tw-mb-4"
      />
      <ctk-skeleton-view
        depth="1"
        class="tw-flex tw-justify-between tw-mb-2"
      >
        <ctk-skeleton-block
          width="200px"
          height="32px"
          level="3"
          loading
        />
        <ctk-skeleton-block
          width="80px"
          height="32px"
          level="3"
          loading
        />
      </ctk-skeleton-view>
      <ctk-skeleton-view
        depth="1"
        class="tw-flex tw-justify-between tw-mb-2"
      >
        <ctk-skeleton-block
          width="230px"
          height="32px"
          level="3"
          loading
        />
        <ctk-skeleton-block
          width="100px"
          height="32px"
          level="3"
          loading
        />
      </ctk-skeleton-view>
    </div>
    <template
      v-else
    >
      <payment-sources-list
        v-if="paymentSources.value"
        :items="paymentSources.value"
        data-test="payment-sources"
      />
      <payment-sources-form
        v-if="stripe"
        :stripe="stripe"
        class="tw-mt-6"
      />
    </template>

    <!-- Dialogs -->
    <delete-payment-source-dialog
      v-model="dialogs.paymentSource.visible"
      :payment-source="dialogs.paymentSource.paymentSource"
      data-test="delete-payment-source"
    />

    <add-payment-source-error-dialog
      v-model="dialogs.error.visible"
      data-test="payment-source-error"
    />
  </div>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'
  import { defineComponent, reactive, ref } from '@vue/composition-api'

  import useStripe from '@/composables/useStripe'
  import store from '@/store'
  import { EventBus } from '@/services/EventBus'

  import CtkInputText from '@/components/CtkInputs/CtkInputText/index.vue'
  import CtkSkeletonView from '@/components/CtkSkeletonView/index.vue'
  import CtkSkeletonBlock from '@/components/CtkSkeletonView/_subs/CtkSkeletonBlock/index.vue'
  import PaymentSourcesList from './components/PaymentSourcesList/index.vue'
  import DeletePaymentSourceDialog from './components/DeletePaymentSourceDialog/index.vue'
  import AddPaymentSourceErrorDialog from './components/AddPaymentSourceErrorDialog/index.vue'
  import PaymentSourcesForm from './components/PaymentSourcesForm/index.vue'

  /**
   * @module view - PaymentSources
   */
  export default defineComponent({
    name: 'PaymentSources',
    components: {
      CtkInputText,
      CtkSkeletonView,
      CtkSkeletonBlock,
      DeletePaymentSourceDialog,
      AddPaymentSourceErrorDialog,
      PaymentSourcesList,
      PaymentSourcesForm
    },
    metaInfo () {
      return {
        title: this.$t('account.payment-sources.title')
      }
    },
    beforeRouteEnter (to, from, next) {
      store.dispatch('setAppReady', true)

      if (!store.getters.isUserShipper) {
        next({
          name: 'AccountProfile'
        })
        return
      }

      next()
    },
    setup () {
      const { stripe } = useStripe()
      const paymentMode = ref(null)
      const paymentSources = ref([])

      const dialogs = reactive({
        paymentSource: {
          visible: false,
          paymentSource: null
        },
        error: {
          visible: false
        }
      })

      return {
        stripe,
        paymentSources,
        paymentMode,
        dialogs
      }
    },
    mounted () {
      /**
       * Fetch company informations
       * (do ignore if we're in a test environment; causing http issues
       * during the unit tests)
       */
      if (process.env.NODE_ENV !== 'test') {
        this.fetchCompany()
        this.fetchPaymentSources()
      }

      EventBus.$on('account:payment-sources:refresh-list', this.fetchPaymentSources)

      EventBus.$on('dialogs:delete-payment-source', (paymentSource) => {
        this.dialogs.paymentSource = {
          visible: true,
          paymentSource
        }
      })

      EventBus.$on('dialogs:payment-source-error', () => {
        this.dialogs.error.visible = true
      })
    },
    computed: {
      ...mapGetters('auth', [
        'getUserInfos'
      ]),
      /**
       * Returns true if there are any pending requests
       * @function hasPendingRequest
       * @returns {boolean}
       */
      hasPendingRequest () {
        return this.$wait.is('fetching company')
      },
      /**
       * @function checks
       * @returns {Array<string>}
       */
      checks () {
        if (this.paymentMode === 'immediate') {
          return [
            // @ts-ignore
            this.$t('app.values.payment_conditions.cash'),
            // @ts-ignore
            this.$t('app.values.payment_conditions.cc')
          ]
        }

        return [
          // @ts-ignore
          this.$t('app.values.payment_conditions.thirty_days_invoice'),
          // @ts-ignore
          this.$t('app.values.payment_conditions.transfer_cc')
        ]
      }
    },
    methods: {
      ...mapActions('auth', [
        'retrieveCompany',
        'retrievePaymentSources'
      ]),
      /**
       * Fetch the payment sources and save it locally.
       * @function fetchPaymentSources
       */
      fetchPaymentSources () {
        this.$wait.start('fetching payment sources')
        return this.retrievePaymentSources(true)
          .then((res) => {
            this.paymentSources.value = res.data.items

            if (this.$matomo) {
              this.$matomo.trackEvent('Account', 'Payment Sources', 'Displayed', this.paymentSources.value.length)
            }
          })
          .finally(() => {
            this.$wait.end('fetching payment sources')
          })
      },
      /**
       * Fetch the company informations
       * @function fetchCompany
       */
      fetchCompany () {
        this.$wait.start('fetching company')
        this.retrieveCompany()
          .then(({ data }) => {
            const { billing } = data
            this.paymentMode = billing.payment_mode
          })
          .catch(() => {})
          .finally(() => {
            this.$wait.end('fetching company')
          })
      }
    },
    beforeDestroy () {
      ['dialogs:delete-payment-source', 'dialogs:payment-source-error', 'account:payment-sources:refresh-list'].forEach(event => {
        EventBus.$off(event)
      })
    }
  })
</script>

<style lang="scss" scoped>

  .payment-sources {
    &__title {
      @media only screen and (max-width: 857px) {
        display: none;
      }
    }

    &__payment-plan {
      &__header,
      &__content {
        border: 1px solid #D2D2D2;
      }

      &__header {
        background-color: $light-gray;
      }

      &__content {
        border-top: none;
      }
    }
  }

</style>
