<template>
  <div
    class="ctk-skeleton-view"
    :class="[`ctk-skeleton-view--depth-${depth}`]"
  >
    <slot />
  </div>
</template>

<script>
  /**
   * @module component - CtkSkeletonView
   * @param {number|string} depth - The depth of the view. The more the value is hight, the more
   * it's transparency increases.
   */
  export default {
    name: 'CtkSkeletonView',
    props: {
      depth: {
        type: [Number, String],
        default: 1
      }
    }
  }
</script>

<style lang="scss" scoped>

  .ctk-skeleton-view {
    &--depth-2 {
      opacity: 0.7;
    }

    &--depth-3 {
      opacity: 0.35;
    }

    &--depth-4 {
      opacity: 0.2;
    }

    &--depth-5 {
      opacity: 0.1;
    }
  }

</style>
