<template>
  <div class="payment-sources-list">
    <div
      class="payment-sources-list__header tw-flex tw-items-center tw-p-3 tw-rounded-tl tw-rounded-tr tw-font-medium"
      data-test="header"
    >
      <div
        v-text="$tc('account.payment-sources.cards', items.length)"
        data-test="title"
      />
      <ui-material-icon
        id="cc-infos"
        name="info"
        class="tw-text-base tw-ml-1"
        data-test="icon"
      />

      <b-tooltip
        target="cc-infos"
      >
        {{ $t('app.paragraphs.save_card') }}
      </b-tooltip>
    </div>
    <div
      class="payment-sources-list__cards tw-rounded-bl tw-rounded-br"
      data-test="cards"
    >
      <template
        v-if="items.length > 0"
      >
        <stripe-card-item-card
          v-for="item in items"
          :key="item.source_id"
          :type="item.card_brand"
          :holder-name="item.card_holder"
          :last-four="item.last_four_digits"
          :expires-at="item.expiration_date"
          class="tw-w-full tw-p-3"
          data-test="card-item"
        >
          <template #suffix>
            <ui-button
              :title="$t('app.buttons.delete')"
              type="button"
              variant="link"
              size="sm"
              class="payment-sources-list__cards__delete tw-ml-2"
              @click="deleteSource(item)"
            >
              <template #left-icon>
                <ui-ctk-icon
                  name="trash"
                />
              </template>
            </ui-button>
          </template>
        </stripe-card-item-card>
      </template>
      <div
        v-else
        data-test="empty"
      >
        <p
          v-text="$t('account.payment-sources.paragraphs.no_cards')"
          class="tw-p-3 tw-mb-0 tw-text-secondary-text"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import { mapActions } from 'vuex'

  import { EventBus } from '@/services/EventBus'
  import StripeCardItemCard from '@/components/Stripe/StripeCardItem/_subs/StripeCardItemCard/index.vue'

  /**
   * @module component - PaymentSourcesList
   * @param {Array<any>} items
   */
  export default {
    name: 'PaymentSourcesList',
    props: {
      items: {
        type: Array,
        required: true
      }
    },
    components: {
      StripeCardItemCard
    },
    methods: {
      ...mapActions([
        'deletePaymentSources'
      ]),
      /**
       * Show the delete source dialog
       * @function deleteSource
       * @param {object} paymentSource
       * @param {string} paymentSource.source_id
       */
      deleteSource (paymentSource) {
        EventBus.$emit('dialogs:delete-payment-source', paymentSource)
      }
    }
  }
</script>

<style lang="scss" scoped>

  .payment-sources-list {
    &__header,
    &__cards {
      border: 1px solid #D2D2D2;
    }

    &__header {
      background-color: $light-gray;
    }

    &__cards {
      border-top: none;

      .stripe-card-item-card:not(:last-child) {
        border-bottom: 1px solid #D2D2D2;
      }
    }
  }

</style>
