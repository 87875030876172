<template>
  <ctk-dialog
    :value="dialogValue"
    modal-class="add-payment-source-error-dialog"
    max-width="765px"
    hide-header
    hide-footer
    persistent
    @handle-close="close"
  >
    <div class="add-payment-source-error-dialog__wrapper">
      <div class="add-payment-source-error-dialog__header tw-flex tw-justify-end">
        <ctk-dialog-close-button
          class="add-payment-source-error-dialog__header__close"
          data-test="close"
          @click.native="close"
        />
      </div>

      <invoices-pay-dialog-payment-status
        :title="$t('account.payment-sources.titles.refused')"
        :button-title="$t('app.buttons.retry_payment')"
        data-test="error"
        @click="close"
      >
        <p
          v-text="$t('account.payment-sources.paragraphs.refused')"
          data-test="content"
        />
      </invoices-pay-dialog-payment-status>
    </div>
  </ctk-dialog>
</template>

<script>
  import CtkDialog from '@/components/CtkDialog/index.vue'
  import CtkDialogCloseButton from '@/components/CtkDialog/_subs/CtkDialogCloseButton/index.vue'
  import InvoicesPayDialogPaymentStatus from '@/views/Shippers/Billing/components/InvoicesPayDialog/_subs/InvoicesPayDialogPaymentStatus/index.vue'

  import useModelGetterSetter from '@/composables/useModelGetterSetter'

  /**
   * @module component - DeletePaymentSourceDialog
   * @param {object} [paymentSource=null]
   */
  export default {
    name: 'DeletePaymentSourceDialog',
    props: {
      value: {
        type: Boolean,
        default: null
      }
    },
    components: {
      CtkDialog,
      CtkDialogCloseButton,
      InvoicesPayDialogPaymentStatus
    },
    setup (props) {
      const { state: dialogValue } = useModelGetterSetter(props, 'value')

      return {
        dialogValue
      }
    },
    methods: {
      close () {
        this.dialogValue = false
      }
    }
  }
</script>

<style lang="scss" scoped>
.add-payment-source-error-dialog__header__close {
  right: 0px;
  top: 0px;
}
</style>

<style lang="scss">

  .add-payment-source-error-dialog .modal-container {
    max-height: initial;
    margin: auto !important;
    width: 100%;
  }

</style>
